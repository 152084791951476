import React from 'react';
import {
  HashRouter,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import Auth from './pages/Auth';
import AppWrapper from './pages/app/AppWrapper';
import r from './utils/wrap-route-component';

function Routing() {
  return (
    <HashRouter>
      <Switch>
        <Route path="/auth" exact render={r(Auth)} />
        <Route path="/" render={r(AppWrapper)} />
        <Redirect from="*" to="/" />
      </Switch>
    </HashRouter>
  );
}

export default Routing;
